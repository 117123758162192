import React from 'react';
import styled from 'styled-components';
import { navigate } from "gatsby";
import { APARTMENT_SELECTION } from '../../routes';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.primary};
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  color: ${({theme}) => theme.colors.white};
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    background-color: #d9375e;
  }

  @media(min-width: 992px) {
    position: absolute;
    top: -30px;
    /* bottom: 0; */
    width: 45%;
    padding: 40px;
  }

  @media(min-width: 1200px) {
    top: -100px;
    padding: 60px;
    /* width: 50%; */
    max-width: 660px;
  }

  @media(min-width: 1600px) {
    width: 50%;
  }
`;

const Title = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  text-align: center;

  @media(min-width: 1200px) {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 5rem;
  }
`;

const SubText = styled.p`
  font-size: 1.125rem;
  /* margin-bottom: 3.5rem; */
  text-align: center;
  margin-bottom: 3rem;

  span {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  @media(min-width: 1200px) {
    margin-bottom: 5rem;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.125rem;
  text-align: center;

  span:first-child {
    margin-bottom: 1rem;
  }
`;

const Price = styled.span`
  border: 1px solid ${({ theme}) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0.5rem 2rem;
  margin-bottom: 0;
`;

function InfoPanel() {
  return (
    <Wrapper onClick={() => navigate(APARTMENT_SELECTION)}>
      <Title>Vyberte si svůj nový byt</Title>
      <SubText>
        <span>1+kk, 2+kk nebo 3+kk</span>
        <br />se zahrádkou, balkonem či terasou
      </SubText>
      <PriceWrapper>
        <span>Ceny od</span>
        <Price>
          3&nbsp;511&nbsp;669&nbsp;Kč
        </Price>
      </PriceWrapper>
    </Wrapper>
  );
}

export default InfoPanel;