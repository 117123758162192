import React from 'react';
import styled, { css } from 'styled-components';
import { ContentWrapper } from "../common";
import { SubText, Title2, Title3, TitleWrapper } from '../Headings';
import { Section } from "../section";
import designSvg from '../../images/svg/icon-design.svg';
import safetySvg from '../../images/svg/icon-safety.svg';
import natureSvg from '../../images/svg/icon-nature.svg';
import citySvg from '../../images/svg/icon-city-connection.svg';

const iconStyle = css`
  height: 80px;
  width: auto;
  fill: ${({theme}) => theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const DesignIcon = styled(designSvg)`
  ${iconStyle};
`;

const SafetyIcon = styled(safetySvg)`
  ${iconStyle};
`;

const NatureIcon = styled(natureSvg)`
  ${iconStyle};
`;

const CityIcon = styled(citySvg)`
  ${iconStyle};
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Item = styled.div`
  text-align: center;

  ${Title3} {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
`;

const Breaker = styled.span`
  @media (min-width: 768px) {
    display: block;
  }
`;

function ProjectAdvantages() {
  return (
    <Section>
      <ContentWrapper>
        <TitleWrapper>
          <Title2>Třešňovku budete mít rádi</Title2>
          <SubText>Prostor, kde se od začátku budete cítit jako doma a&nbsp;také místo, jež kloubí dobrý vkus a&nbsp;aktuální technologie – to je rezidence Třešňovka, nový výrazný prvek starobylého královského města. V&nbsp;čem je unikátní?</SubText>
        </TitleWrapper>
        <Grid>
          <Item>
            <DesignIcon />
            <Title3>Design <Breaker />a&nbsp;kvalita</Title3>
            <p>Uhlazený styl celé nemovitosti vzniká z&nbsp;prvotřídních materiálů, které jen tak nezestárnou.</p>
          </Item>

          <Item>
            <SafetyIcon />
            <Title3>Důraz <Breaker />na bezpečí</Title3>
            <p>Hlídané společné prostory a&nbsp;soukromé parkoviště přímo v&nbsp;areálu umocňuje dobrý pocit.</p>
          </Item>

          <Item>
            <NatureIcon />
            <Title3>Blízkost <Breaker />k&nbsp;přírodě</Title3>
            <p>Pro únik od každodenního shonu skvěle slouží třešňový sad vzdálený jen pár kroků.</p>
          </Item>

          <Item>
            <CityIcon />
            <Title3>Spojení <Breaker />s&nbsp;městem</Title3>
            <p>Díky výbornému umístění je vše blízko – centrum města,  obchody, zdravotní péče, vzdělání i&nbsp;to ostatní.</p>
          </Item>
        </Grid>
      </ContentWrapper>
    </Section>
  );
}

export default ProjectAdvantages;